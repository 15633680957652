import { FC, SVGProps } from 'react';

const HomeIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color,
  size = 16,
  ...props
}) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.49996 0.899734L0.166626 7.49999H2.16663V13.5H6.83329V9.49999H8.16663V13.5H12.8333V7.49999H14.8333L7.49996 0.899734ZM7.49996 2.69401L11.5 6.29427V6.83333V12.1667H9.49996V8.16666H5.49996V12.1667H3.49996V6.29427L7.49996 2.69401Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default HomeIcon;
