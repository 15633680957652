import { atomWithReset } from 'jotai/utils';
import {
  SocketConnectionAtom,
  SocketConnectionReducerAction,
  SocketConnectionReducerActionTypes,
} from '../typings/socket-connection';

export const socketConnectionAtom = atomWithReset<SocketConnectionAtom>({});

export const socketConnectionReducer = (
  prev: SocketConnectionAtom,
  action: SocketConnectionReducerAction
): SocketConnectionAtom => {
  const { type, data } = action;

  if (type === SocketConnectionReducerActionTypes.CONNECTION) {
    return {
      ...prev,
      [data.name]: data.status,
    };
  }

  return prev;
};
