export interface SocketConnectionAtom {
  [key: string]: boolean;
}

export enum SocketConnectionReducerActionTypes {
  CONNECTION = 'CONNECTION',
}

export enum SocketName {
  CONVERSATION = 'conversation',
  ORGANIZATION = 'organization',
}

interface ConnectionAction {
  type: SocketConnectionReducerActionTypes.CONNECTION;
  data: {
    name: string;
    status: boolean;
  };
}

export type SocketConnectionReducerAction = ConnectionAction;
