import { useEffect, useRef } from 'react';
import { ROUTES } from '../constants/routes';

export const SOUND_NOTIFICATION_LS_DISABLED = 'SOUND_NOTIFICATION_DT_KEY';

const usePlayNotificationSound = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const messageIdSet = useRef<Set<string>>(new Set());

  function isDTVisible() {
    return document.visibilityState === 'visible';
  }

  const isSoundEnabled = () => {
    if (
      window.localStorage.getItem(SOUND_NOTIFICATION_LS_DISABLED) !== 'true' &&
      !isDTVisible()
    ) {
      return true;
    }
    return (
      window.localStorage.getItem(SOUND_NOTIFICATION_LS_DISABLED) !== 'true' &&
      !window.location.pathname?.includes(ROUTES.CONVERSATIONS)
    );
  };

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/notification-alert.mp3'); // Path to your sound file
    }

    const playNotificationSound = (event: MessageEvent) => {
      if (event.data && event.data.type === 'DT_PLAY_SOUND') {
        const messageId = event.data.id;

        if (messageId === undefined || !isSoundEnabled()) return; // Early return if messageId is undefined

        // Check if the message ID is already in the set
        if (!messageIdSet.current.has(messageId)) {
          // Add the new message ID to the set
          messageIdSet.current.add(messageId);

          // Limit the size of the set to 20
          if (messageIdSet.current.size > 20) {
            const iterator = messageIdSet.current.values();
            messageIdSet.current.delete(iterator.next().value ?? ''); // Remove the oldest ID
          }

          // Play the notification sound
          const audio = audioRef.current;
          if (audio && audio.paused) {
            audio.play().catch((error) => {
              console.error('Sound playback failed:', error);
            });
          }
        }
      }
    };

    navigator.serviceWorker.addEventListener('message', playNotificationSound);

    return () => {
      navigator.serviceWorker.removeEventListener(
        'message',
        playNotificationSound
      );
    };
  }, []);

  useEffect(() => {
    const playNotificationSound = (event: MessageEvent) => {
      if (event.data && event.data.type === 'DT_PLAY_SOUND') {
        const messageId = event.data.id;
        if (messageId === undefined || !isSoundEnabled()) return; // Early return if messageId is undefined

        // Check if the message ID is already in the set
        if (!messageIdSet.current.has(messageId)) {
          // Add the new message ID to the set
          messageIdSet.current.add(messageId);

          // Limit the size of the set to 20
          if (messageIdSet.current.size > 20) {
            const iterator = messageIdSet.current.values();
            messageIdSet.current.delete(iterator.next().value ?? ''); // Remove the oldest ID
          }

          // Play the notification sound
          const audio = audioRef.current;
          if (audio && audio.paused) {
            audio.play().catch((error) => {
              console.error('Sound playback failed:', error);
            });
          }
        }
      }
    };

    window.addEventListener('message', playNotificationSound);

    return () => {
      window.removeEventListener('message', playNotificationSound);
    };
  }, []);

  return null;
};

export default usePlayNotificationSound;
