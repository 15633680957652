import { FC, SVGProps } from 'react';

const StarIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  size = 24,
  fill = '#2d2e2d',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 24"
      width={size}
      height={size}
      {...props}
    >
      <path
        fill={fill}
        d="M10.336 20.527a1.362 1.362 0 0 1-1.245-.793l-1.918-4.108-4.048-1.856a1.375 1.375 0 0 1 0-2.491l4.111-1.917 1.856-4.048a1.362 1.362 0 0 1 1.245-.794 1.363 1.363 0 0 1 1.245.794L13.5 9.423l4.048 1.854a1.376 1.376 0 0 1 0 2.493l-4.109 1.918-1.856 4.046a1.362 1.362 0 0 1-1.245.793Zm-6.332-8 3.866 1.8c.294.138.53.375.665.67l1.8 3.858 1.8-3.864c.137-.29.372-.525.663-.662l3.867-1.806-3.865-1.8a1.4 1.4 0 0 1-.662-.66l-1.806-3.868-1.8 3.864a1.374 1.374 0 0 1-.659.663l-3.869 1.806ZM19.097 21.448a.75.75 0 0 1-.75-.75v-1.236h-1.236a.75.75 0 0 1 0-1.5h1.237v-1.235a.75.75 0 1 1 1.5 0v1.235h1.236a.75.75 0 0 1 0 1.5h-1.236V20.7a.75.75 0 0 1-.75.748ZM17.257 6.669a.75.75 0 0 1-.75-.75v-.56h-.56a.75.75 0 0 1 0-1.5h.56v-.558a.75.75 0 1 1 1.5 0v.558h.558a.75.75 0 1 1 0 1.5h-.558v.559a.751.751 0 0 1-.75.75Z"
      />
    </svg>
  );
};

export default StarIcon;
