import { FC, SVGProps } from 'react';

const InboxIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill={color}
      {...props}
    >
      <path
        d="M2.05561 12.2143C1.7064 12.2143 1.40746 12.09 1.15878 11.8413C0.910102 11.5926 0.785767 11.2937 0.785767 10.9444V2.05556C0.785767 1.70635 0.910102 1.40741 1.15878 1.15873C1.40746 0.910056 1.7064 0.785721 2.05561 0.785721H10.9445C11.2937 0.785721 11.5926 0.910056 11.8413 1.15873C12.09 1.40741 12.2143 1.70635 12.2143 2.05556V10.9444C12.2143 11.2937 12.09 11.5926 11.8413 11.8413C11.5926 12.09 11.2937 12.2143 10.9445 12.2143H2.05561ZM2.05561 10.9444H10.9445V9.03966H9.03971C8.7223 9.4418 8.34394 9.75395 7.90485 9.9762C7.46569 10.1984 6.99742 10.3095 6.50005 10.3095C6.0027 10.3095 5.53444 10.1984 5.09529 9.9762C4.65614 9.75395 4.27783 9.4418 3.96037 9.03966H2.05561V10.9444ZM6.50005 9.03966C6.83864 9.03966 7.15087 8.95243 7.43659 8.7778C7.7223 8.60317 7.95506 8.373 8.13495 8.08728C8.19849 7.99204 8.2778 7.91532 8.37304 7.85719C8.46828 7.79898 8.57411 7.76987 8.69053 7.76987H10.9445V2.05556H2.05561V7.76987H4.30958C4.42598 7.76987 4.5318 7.79898 4.62704 7.85719C4.72228 7.91532 4.80164 7.99204 4.86513 8.08728C5.04503 8.373 5.27783 8.60317 5.56354 8.7778C5.84926 8.95243 6.16146 9.03966 6.50005 9.03966Z"
        fill={color}
      />
    </svg>
  );
};
export default InboxIcon;
