import { FC, SVGProps } from 'react';

const SettingsIconSmall: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  size,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
    >
      <path
        d="M10.5 10.5C9.39733 10.5 8.5 9.60267 8.5 8.5C8.5 7.39733 9.39733 6.5 10.5 6.5C11.6027 6.5 12.5 7.39733 12.5 8.5C12.5 9.60267 11.6027 10.5 10.5 10.5Z"
        stroke="#2D2D2D"
        strokeWidth="1.66667"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0993 7.74533C15.1447 8.02333 15.1667 8.27 15.1667 8.5C15.1667 8.73 15.1447 8.97667 15.0993 9.25467L15.04 9.62067L16.1987 10.6287L15.192 12.3713L13.744 11.8727L13.456 12.1087C13.0713 12.4247 12.6307 12.678 12.146 12.8607L11.7987 12.992L11.506 14.5H9.49399L9.20066 12.9927L8.85333 12.8613C8.36866 12.6787 7.92799 12.4253 7.54333 12.1093L7.25533 11.8733L5.80733 12.372L4.80066 10.6293L5.95933 9.62133L5.89999 9.25533C5.85533 8.97667 5.83333 8.73 5.83333 8.5C5.83333 8.27 5.85533 8.02333 5.90066 7.74533L5.95999 7.37933L4.80066 6.37133L5.80733 4.62867L7.25533 5.12733L7.54333 4.89133C7.92799 4.57533 8.36866 4.322 8.85333 4.13933L9.20066 4.008L9.49399 2.5H11.506L11.7993 4.00733L12.1467 4.13867C12.6313 4.32133 13.072 4.57467 13.4567 4.89067L13.7447 5.12667L15.1927 4.628L16.1993 6.37067L15.0407 7.37867L15.0993 7.74533Z"
        stroke="#2D2D2D"
        strokeWidth="1.66667"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIconSmall;
