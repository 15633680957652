import { useCallback, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { getTimeDiffJson } from '../utils/get-time-from-timestamp';
import { SocketStatus } from '../typings';
import { debounce } from '@mui/material';

const useSocket = (
  getSocket: () => Socket,
  onReconnect?: (lastUpdatedTimestamp: number) => void,
  checkSocketDisconnect?: boolean,
  onConnectionStatusChange?: (status: boolean) => void
) => {
  const [socket, setSocket] = useState(getSocket());
  const socketName = ((socket as any).nsp! ?? '').substring(1);
  const savedCurrentTimeRef = useRef<number>(new Date().getTime());
  const [lastPongReceived, setLastPongReceived] = useState(true);

  const isConnectedRef = useRef(false);
  const lastUpdatedTimestampRef = useRef(new Date().getTime());
  const onReconnectRef = useRef(onReconnect);
  const BUFFER_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds prev msgs will be fetched
  // we will update on mouseMove and on Socket msg received
  const lastSocketMsgTimeRef = useRef(new Date().getTime());

  onReconnectRef.current = onReconnect;

  const addListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.on(eventName, listener);
    },
    [socket]
  );

  const removeListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.off(eventName, listener);
    },
    [socket]
  );

  useEffect(() => {
    // setSocket(getSocket());
    const newSocket = getSocket();
    if (newSocket !== socket) {
      setSocket(newSocket);
    }
  }, [getSocket, setSocket]);

  useEffect(() => {
    if (!checkSocketDisconnect) return;
    const PING_INTERVAL = 5000;
    const PONG_TIMEOUT = 3000;

    let pongTimeout: NodeJS.Timeout | null = null;

    const sendPing = () => {
      if (!lastPongReceived) {
        // No pong received for the last ping
        // console.error("No pong received! Connection lost.");
        onConnectionStatusChange?.(false);
        return;
      }

      setLastPongReceived(false); // Reset before sending a new ping
      socket.emit('ping'); // Send ping to the server

      // Set a timeout to check for pong
      pongTimeout = setTimeout(() => {
        if (!lastPongReceived) {
          // console.error("Pong not received within timeout!");
          onConnectionStatusChange?.(false);
        }
      }, PONG_TIMEOUT);
    };

    // Listen for pong from server
    socket.on('pong', (data) => {
      if (pongTimeout) clearTimeout(pongTimeout);
      setLastPongReceived(true);
      onConnectionStatusChange?.(true);
    });

    const interval = setInterval(sendPing, PING_INTERVAL);

    return () => {
      setLastPongReceived(true);
      clearInterval(interval);
      if (pongTimeout) clearTimeout(pongTimeout);
      socket.off('pong');
    };
  }, [lastPongReceived, checkSocketDisconnect]);

  useEffect(() => {
    const updateLastMouseMoveTime = () => {
      lastSocketMsgTimeRef.current = new Date().getTime();
    };
    socket.on('message', updateLastMouseMoveTime);
    return () => {
      socket.off('message', updateLastMouseMoveTime);
    };
  }, []);

  useEffect(() => {
    const onConnect = () => {
      updateSocketStatus();
      isConnectedRef.current = true;
      onConnectionStatusChange?.(true);
    };

    const onDisconnect = (reason: Socket.DisconnectReason) => {
      if (window.socketStatus) {
        window.socketStatus[socketName] = {
          status: SocketStatus.NOT_CONNECTED,
          message: reason || 'Unknown reason',
        };
      } else {
        window.socketStatus = {
          [socketName]: {
            status: SocketStatus.NOT_CONNECTED,
            message: reason || 'Unknown reason',
          },
        };
      }
      onConnectionStatusChange?.(false);

      if (navigator.onLine) {
        socket.connect();
        return;
      }
      isConnectedRef.current = false;
      lastUpdatedTimestampRef.current = new Date().getTime();
      // socket.disconnect();
    };

    socket.connect();
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.disconnect();
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket]);

  const updateSocketStatus = () => {
    if (socket.connected) {
      if (window.socketStatus) {
        window.socketStatus[socketName] = {
          status: SocketStatus.CONNECTED,
          message: 'Connected successfully',
        };
      } else {
        window.socketStatus = {
          [socketName]: {
            status: SocketStatus.CONNECTED,
            message: 'Connected successfully',
          },
        };
      }
    }
  };
  useEffect(() => {
    updateSocketStatus();
    onConnectionStatusChange?.(socket.connected);
  }, [socket.connected]);

  useEffect(() => {
    const refreshChat = (event?: Event, lastSyncTime?: number) => {
      const lastUpdatedTime = lastSyncTime || lastUpdatedTimestampRef.current;
      if (lastUpdatedTime) {
        const { elapsedHours } = getTimeDiffJson(lastUpdatedTime);

        if (elapsedHours > 6) {
          window.location.reload();
          return;
        }
      }

      socket.disconnect();
      socket.connect();
      onReconnectRef.current?.(lastUpdatedTime);
      lastUpdatedTimestampRef.current = new Date().getTime();
      // lastSocketMsgTimeRef.current = lastUpdatedTimestampRef.current;
    };
    const reConnect = () => {
      if (isConnectedRef.current && socket.connected) {
        updateSocketStatus();
        onConnectionStatusChange?.(true);
        return;
      }
      refreshChat();
    };

    const handleOnVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        reConnect();
      }
    };

    window.addEventListener('focus', reConnect);
    window.addEventListener('online', refreshChat);
    window.addEventListener('visibilitychange', handleOnVisibilityChange);

    return () => {
      window.removeEventListener('focus', reConnect);
      window.removeEventListener('online', refreshChat);
      window.removeEventListener('visibilitychange', handleOnVisibilityChange);
      // window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [socket, socket.connected, socketName]);

  return { socket, addListener, removeListener };
};

export default useSocket;
