import { FC, SVGProps } from 'react';

const TemplateAttachmentSmall: FC<
  SVGProps<SVGSVGElement> & { size?: number }
> = ({ size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="#2d2d2d"
      {...props}
    >
      <path
        d="M4.49996 3.16666C3.76329 3.16666 3.16663 3.76333 3.16663 4.5V12.5C3.16663 13.2367 3.76329 13.8333 4.49996 13.8333H11.8333V12.5H4.49996V5.83463L9.83329 9.16666L15.1666 5.83463V9.16666H16.5V4.5C16.5 3.76333 15.9033 3.16666 15.1666 3.16666H4.49996ZM4.49996 4.5H15.1666V4.5013L9.83329 7.83333L4.49996 4.5013V4.5ZM15.1666 10.5V12.5H13.1666V13.8333H15.1666V15.8333H16.5V13.8333H18.5V12.5H16.5V10.5H15.1666Z"
        fill="#2d2d2d"
      />
    </svg>
  );
};

export default TemplateAttachmentSmall;
