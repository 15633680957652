import { SocketUpdates } from '../typings/socket-updates';
import { get } from '../../infra/rest';

export const getSocketUpdates = async (lastUpdateTimestamp: number) => {
  return get<SocketUpdates>(
    `/v3/updates?lastUpdateTimestamp=${lastUpdateTimestamp}`,
    {},
    true
  );
};

export default getSocketUpdates;
