import { FC, SVGProps } from 'react';

const LogoutIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  size = 24,
  color,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill={color}
      {...props}
    >
      <path
        d="M9.33337 1.83334V8.50001H10.6667V1.83334H9.33337ZM8.00004 2.13933C5.29537 2.98933 3.33337 5.51534 3.33337 8.50001C3.33337 12.182 6.31804 15.1667 10 15.1667C13.682 15.1667 16.6667 12.182 16.6667 8.50001C16.6667 5.51534 14.7047 2.98933 12 2.13933V3.5586C13.954 4.34994 15.3334 6.26268 15.3334 8.50001C15.3334 11.4453 12.9454 13.8333 10 13.8333C7.05471 13.8333 4.66671 11.4453 4.66671 8.50001C4.66671 6.26201 6.04604 4.34994 8.00004 3.5586V2.13933Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default LogoutIcon;
