import { FC, SVGProps } from 'react';

const BotsIconSmall: FC<SVGProps<SVGSVGElement>> = ({
  color = '#2d2d2d',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      {...props}
    >
      <path
        d="M10.5 1.83333C10.1464 1.83333 9.80728 1.97381 9.55723 2.22386C9.30718 2.4739 9.16671 2.81304 9.16671 3.16667C9.16689 3.40054 9.22859 3.63024 9.3456 3.83273C9.46262 4.03523 9.63084 4.20338 9.83337 4.32031V5.83333H7.83337C6.36848 5.83333 5.16671 7.03511 5.16671 8.5V11.1667H3.83337V12.5H5.16671V13.8333C5.16671 14.562 5.77133 15.1667 6.50004 15.1667H14.5C15.2287 15.1667 15.8334 14.562 15.8334 13.8333V12.5H17.1667V11.1667H15.8334V8.5C15.8334 7.03511 14.6316 5.83333 13.1667 5.83333H11.1667V4.31901C11.3691 4.20218 11.5371 4.03424 11.6542 3.832C11.7712 3.62975 11.833 3.40031 11.8334 3.16667C11.8334 2.81304 11.6929 2.4739 11.4428 2.22386C11.1928 1.97381 10.8537 1.83333 10.5 1.83333ZM7.83337 7.16667H9.83337H11.1667H13.1667C13.9112 7.16667 14.5 7.75555 14.5 8.5V13.8333H12.5V12.5H8.50004V13.8333H6.50004V8.5C6.50004 7.75555 7.08893 7.16667 7.83337 7.16667ZM8.83337 9.16667C8.56816 9.16667 8.3138 9.27202 8.12627 9.45956C7.93873 9.64709 7.83337 9.90145 7.83337 10.1667C7.83337 10.4319 7.93873 10.6862 8.12627 10.8738C8.3138 11.0613 8.56816 11.1667 8.83337 11.1667C9.09859 11.1667 9.35294 11.0613 9.54048 10.8738C9.72802 10.6862 9.83337 10.4319 9.83337 10.1667C9.83337 9.90145 9.72802 9.64709 9.54048 9.45956C9.35294 9.27202 9.09859 9.16667 8.83337 9.16667ZM12.1667 9.16667C11.9015 9.16667 11.6471 9.27202 11.4596 9.45956C11.2721 9.64709 11.1667 9.90145 11.1667 10.1667C11.1667 10.4319 11.2721 10.6862 11.4596 10.8738C11.6471 11.0613 11.9015 11.1667 12.1667 11.1667C12.4319 11.1667 12.6863 11.0613 12.8738 10.8738C13.0613 10.6862 13.1667 10.4319 13.1667 10.1667C13.1667 9.90145 13.0613 9.64709 12.8738 9.45956C12.6863 9.27202 12.4319 9.16667 12.1667 9.16667Z"
        fill={color}
      />
    </svg>
  );
};

export default BotsIconSmall;
