import { getCurrentUserId } from '../../infra/firebase/init';
import {
  ChatRowData,
  NewMessageChatRow,
} from '../../modules/home/v1/components/chat-list-section/chat-row/typings';

const chatRowDataHelper = (
  newData: NewMessageChatRow,
  oldData?: ChatRowData
): ChatRowData => {
  const currentUserId = getCurrentUserId();
  const modifiedData = {
    ...oldData,
    ...newData.chat,
    id: newData.chatId,
    unreadCount: newData.chat.unreadCount,
    assignedUserId: newData.chat.assignedUserId,
    assignedUserName: newData.chat.assignedUserName,
    integrationId: newData.integrationId,
    integrationWabaNumber: newData.integrationWabaNumber,
    integrationWabaPhoneName: newData.integrationWabaPhoneName,
    integrationDisplayName: newData.integrationDisplayName,
    lastMessage: {
      ...(oldData ? oldData.lastMessage : {}),
      message: newData.message,
      id: newData.messageId,
      templateId: null,
      senderId: newData.senderId,
      messageOriginType: newData.senderType,
      messageTime: newData.messageTime,
    },
  };

  if (!!newData.validUsersCustomerName) {
    const nameFromSocket = newData.validUsersCustomerName.find(
      (item) => item.userId === currentUserId
    );
    if (nameFromSocket) {
      modifiedData.name = nameFromSocket.name;
    }
  }
  return modifiedData;
};

export default chatRowDataHelper;
