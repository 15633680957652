import { SocketName } from '../typings/socket-connection';

export const QUERY_PARAM_BASE_KEY = 'trapKey';

export const LOADING = 'Loading...';

export const MUI_LICENCE_KEY =
  'e81fbce53ecd8cbaf64378b3108ef75aTz02MDEyNCxFPTE3MDgyNDg0NTE3ODMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const NAME_REGEX = /^[a-zA-Z0-9\s]+$/;

export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const VARIABLE_DEFAULT_LENGTH = 10;

export const TAGIFY_REGEX = /\[\[(.*?)\]\]/g;

export const TEXT_INPUT_LIMITER_HEIGHT = 18;

export const VARIABLE_REGEX_COMBINED = /{{.+?}}|\[\[(.*?)\]\]/g;

export const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const CUSTOM_FIELDS_ID_REGEX = /"id":"([^"]+)"/g;

export const FLAGGED_CUSTOM_FIELDS_REMOVE_REGEX = /{{[^{}]+}}/g;

export const CUSTOM_FIELDS_TEXT_VALUES_REGEX = /\[\[.*?\]\]/g;

export const URL_REGEX =
  /^(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])$/;

export const TEAM_MEMBER = 'TEAM_MEMBER';

export const DEFAULT_TEAM = 'DEFAULT_TEAM';

export const DEFAULT_MEMBER = 'DEFAULT_MEMBER';

export const SINGLE_WABA_CHECK = 'SINGLE_WABA_CHECK';

export const DEFAULT_INTEGRATION = 'DEFAULT_INTEGRATION';

export const UNICODE_ZERO_WIDTH_REGEX = /[\u200B-\u200D\uFEFF]/g;

export const INACTIVE_STATUS_TIMER_SECONDS = 10 * 60;

export const UNLIMITED = 'unlimited';

export const DT_ROADMAP_LINK =
  'https://roadmap.doubletick.io/doubletick-product-roadmap';

export const PHONE_NUMBER_FIELD_VALUE = 'Phone Number';
export const REQUEST_INTEGRATION_FORM_LINK = `https://docs.google.com/forms/d/e/1FAIpQLSesBgD9CauIlA4RZHNb5jlzEVAP8DclXXez5CrCyx1Hr6bLWQ/viewform?usp=pp_url&entry.205083235=`;

export const CONVERSATIONS_PAGE_VALID_SOCKET_NAMES = Object.values(SocketName);
