import { FC, SVGProps } from 'react';

const GroupsOutlinedIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill={color}
      {...props}
    >
      <path
        d="M15.1667 2.5L14.5 3.83333L8.5 5.83333H5.83333C5.1 5.83333 4.5 6.43333 4.5 7.16667V9.83333C4.5 10.5667 5.1 11.1667 5.83333 11.1667H7.16667V14.5H8.5V11.1667L14.5 13.1667L15.1667 14.5H16.5V9.64844C16.8971 9.41759 17.1667 8.99263 17.1667 8.5C17.1667 8.00737 16.8971 7.58241 16.5 7.35156V2.5H15.1667ZM15.1667 5.01693V11.9831L14.9219 11.901L9.83333 10.2057V6.79427L14.9219 5.09896L15.1667 5.01693ZM5.83333 7.16667H8.5V9.83333H7.16667H5.83333V7.16667Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupsOutlinedIcon;
