import { FC, SVGProps } from 'react';

const RequestsIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M10.6667 8C8.82667 8 7.33333 9.49333 7.33333 11.3333C7.33333 13.1733 8.82667 14.6667 10.6667 14.6667C12.5067 14.6667 14 13.1733 14 11.3333C14 9.49333 12.5067 8 10.6667 8ZM11.7667 12.9L10.3333 11.4667V9.33333H11V11.1933L12.2333 12.4267L11.7667 12.9ZM11.3333 2H9.21333C8.93333 1.22666 8.2 0.666664 7.33333 0.666664C6.46667 0.666664 5.73333 1.22666 5.45333 2H3.33333C2.6 2 2 2.6 2 3.33333V13.3333C2 14.0667 2.6 14.6667 3.33333 14.6667H7.40667C7.01333 14.2867 6.69333 13.8333 6.46 13.3333H3.33333V3.33333H4.66667V5.33333H10V3.33333H11.3333V6.72C11.8067 6.78666 12.2533 6.92666 12.6667 7.12V3.33333C12.6667 2.6 12.0667 2 11.3333 2ZM7.33333 3.33333C6.96667 3.33333 6.66667 3.03333 6.66667 2.66666C6.66667 2.3 6.96667 2 7.33333 2C7.7 2 8 2.3 8 2.66666C8 3.03333 7.7 3.33333 7.33333 3.33333Z"
        fill={color}
      />
    </svg>
  );
};

export default RequestsIcon;
