import {
  ForwardedRef,
  forwardRef,
  useState,
  memo,
  ImgHTMLAttributes,
} from 'react';

interface Props {
  readonly placeholderSrc?: string;
  readonly imgProps?: Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>;
  readonly src: string;
}

const ProgressiveImage = memo(
  forwardRef(
    (
      { placeholderSrc, src, imgProps = {} }: Props,
      ref: ForwardedRef<HTMLImageElement>
    ) => {
      const [currentSrc, setCurrentSrc] = useState(src);
      const [retryCount, setRetryCount] = useState(0);
      const [isImageLoaded, setIsImageLoaded] = useState(false);

      const MAX_RETRIES = 4;
      const RETRY_DELAY = 2000;

      const handleImageError = () => {
        if (retryCount < MAX_RETRIES) {
          setTimeout(() => {
            setRetryCount(retryCount + 1);
            setCurrentSrc(`${src}?retry=${retryCount + 1}`);
          }, RETRY_DELAY);
        } else {
          setCurrentSrc(placeholderSrc || '');
        }
      };

      const handleImageLoad = () => {
        setIsImageLoaded(true);
      };

      return (
        <>
          <img
            alt=""
            ref={ref}
            {...(imgProps || {})}
            style={{
              ...imgProps.style,
              height: isImageLoaded ? '0px' : imgProps.style?.height,
              width: isImageLoaded ? '0px' : imgProps.style?.width,
            }}
            src={placeholderSrc}
          />
          <img
            alt=""
            ref={ref}
            {...(imgProps || {})}
            style={{
              ...imgProps.style,
              height: !isImageLoaded ? '0px' : imgProps.style?.height,
              width: !isImageLoaded ? '0px' : imgProps.style?.width,
            }}
            src={currentSrc}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </>
      );
    }
  ),
  (prevProps: Props, nextProps: Props) => {
    return (
      prevProps.src === nextProps.src &&
      prevProps.placeholderSrc === nextProps.placeholderSrc &&
      Object.keys(prevProps.imgProps?.style ?? {}) ===
        Object.keys(nextProps.imgProps?.style ?? {})
    );
  }
);

export default ProgressiveImage;
