import { useCallback, useRef } from 'react';
import { useAtomValue } from 'jotai';
import { chatListAtom } from '../states/chat-list';
import { ChatStatuses } from '../typings';

export function useChatListFilter() {
  const {
    searchTerm,
    status,
    tags,
    assignedUserId,
    customList,
    waba,
    slaStatus,
  } = useAtomValue(chatListAtom);

  const isAnyFilterSelected = useCallback(() => {
    return !!(
      searchTerm ||
      status !== ChatStatuses.ALL ||
      (tags ?? []).length > 0 ||
      assignedUserId ||
      Object.keys(customList ?? {}).length > 0 ||
      (waba ?? []).length > 0 ||
      slaStatus === 'breached'
    );
  }, [searchTerm, status, tags.length, assignedUserId, customList, waba, slaStatus]);

  const isFilterSelectRef = useRef(isAnyFilterSelected);
  isFilterSelectRef.current = isAnyFilterSelected;

  return {
    isFilterSelectRef,
    searchTerm,
    status,
    tags,
    assignedUserId,
    customList,
  };
}
