/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import {
  CONNECTION_LOST_STATUS_BAR_HEIGHT,
  HEADER_HEIGHT,
} from '../../../typings';
import { ReactComponent as NoConnectionIcon } from '../../../assets/svg/disconnect.svg';
import { QSButtonBase, QSTypography } from '../../atoms';
import { FC, useEffect, useState } from 'react';
import { ButtonProps } from '@mui/material';
import Loader from '../../molecules/loader';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

const ConnectionLostBar: FC<{
  isVisible?: boolean;
  ctaText?: string;
  ctaProps?: ButtonProps;
  isOnline?: boolean;
}> = ({ isVisible, ctaText = 'Refresh', ctaProps, isOnline }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    if (isOnline) {
      window.location.reload();
      return;
    }

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div
      css={css`
        height: ${isVisible ? CONNECTION_LOST_STATUS_BAR_HEIGHT : 0}px;
        width: 100%;
        display: ${isVisible ? 'flex' : 'none'};
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        position: fixed;
        top: 0px;
        border-radius: 4px;
        border-bottom: 1px solid#e0e0e9;
        background: #fbebeb;
        box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);
        animation: ${isVisible ? slideIn : slideOut} 500ms ease-in-out;
      `}
    >
      <NoConnectionIcon
        css={css`
          width: 20px;
          height: 20px;
        `}
      />
      <QSTypography
        css={css`
          color: #2d2d2d;
          font-size: 14px;
          font-weight: 600;
        `}
      >
        Connection lost
      </QSTypography>
      <QSTypography
        css={css`
          color: #585858;
          font-size: 14px;
          font-weight: 400;
        `}
      >
        Check your internet connection and refresh
      </QSTypography>
      <QSButtonBase
        css={css`
          display: flex;
          padding: 4px 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid rgba(211, 47, 47, 0.5);
          cursor: ${isOnline ? 'pointer' : 'unset'};
        `}
        onClick={handleButtonClick}
        {...ctaProps}
      >
        {isLoading ? (
          <Loader
            size={16}
            loaderProps={{
              style: {
                color: '#d32f2f',
              },
            }}
          />
        ) : (
          <QSTypography
            css={css`
              color: #d32f2f;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: -0.13px;
            `}
          >
            {ctaText}
          </QSTypography>
        )}
      </QSButtonBase>
    </div>
  );
};

export default ConnectionLostBar;
