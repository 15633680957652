import { FC, SVGProps } from 'react';

const WalletIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path
        d="M16 4.85333V3.33333C16 2.6 15.4 2 14.6667 2H5.33333C4.59333 2 4 2.6 4 3.33333V12.6667C4 13.4 4.59333 14 5.33333 14H14.6667C15.4 14 16 13.4 16 12.6667V11.1467C16.3933 10.9133 16.6667 10.4933 16.6667 10V6C16.6667 5.50667 16.3933 5.08667 16 4.85333ZM15.3333 6V10H10.6667V6H15.3333ZM5.33333 12.6667V3.33333H14.6667V4.66667H10.6667C9.93333 4.66667 9.33333 5.26667 9.33333 6V10C9.33333 10.7333 9.93333 11.3333 10.6667 11.3333H14.6667V12.6667H5.33333Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default WalletIcon;
